import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"d-flex flex-column",attrs:{"height":"100%","elevation":"5"}},[_c(VCardTitle,{staticClass:"mt-4 justify-center"},[(_vm.product.price > 0)?_c('h1',[_c('span',{staticStyle:{"position":"relative","font-size":"1.4rem","left":"-0.2rem","top":"-0.6rem"}},[_vm._v(" € ")]),_vm._v(" "+_vm._s(_vm.productPrice)+" "),_c('span',{staticStyle:{"position":"relative","font-size":"1.2rem","left":"-0.4rem"}},[_vm._v(" "+_vm._s(_vm.productPeriod)+" ")])]):_c('h1',[_c('span',{staticStyle:{"position":"relative","font-size":"1.4rem","left":"-0.2rem","top":"-0.6rem"}},[_vm._v(" € ")]),_vm._v(" 0 "),_c('span',{staticStyle:{"position":"relative","font-size":"1.2rem","left":"-0.4rem"}},[_vm._v(" "+_vm._s(_vm.productPeriod)+" ")])])]),_c(VCardSubtitle,{staticClass:"d-flex justify-center"},[(_vm.product.price === 0)?_c(VChip,{staticClass:"mt-2",attrs:{"label":"","outlined":"","color":"success"}},[_vm._v(" Piano gratuito ")]):_vm._e(),(_vm.product.price > 0)?_c(VSwitch,{attrs:{"inset":"","true-value":"annual","false-value":"monthly","label":_vm.productTypology},model:{value:(_vm.priceTypologyToShow),callback:function ($$v) {_vm.priceTypologyToShow=$$v},expression:"priceTypologyToShow"}}):_vm._e()],1),_c(VDivider),_c(VCardText,[_c('div',{staticClass:"d-flex"},[_c(VList,{attrs:{"disabled":""}},[_c(VListItemGroup,{attrs:{"color":"primary"}},_vm._l((_vm.longDescription),function(item,index){return _c(VListItem,{key:index},[_c(VListItemIcon,[_c(VIcon,[_vm._v(_vm._s(item.icon))])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(" "+_vm._s(item.title)+" ")])],1)],1)}),1)],1)],1)]),_c(VSpacer),_c(VCardActions,{staticClass:"pa-6"},[_c(VBtn,{attrs:{"block":"","color":"primary","loading":_vm.loading.chooseSubscription},on:{"click":function($event){return _vm.handleChooseSubscription(_vm.product.id)}}},[_vm._v(" SELEZIONA ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }