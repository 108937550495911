<template>
  <v-card
    class="d-flex flex-column"
    height="100%"
    elevation="5"
  >
    <v-card-title
      class="mt-4 justify-center"
    >
      <h1 v-if="product.price > 0">
        <span
          style="position: relative; font-size: 1.4rem; left:-0.2rem; top: -0.6rem;"
        >
          &euro;
        </span>
        {{ product.price }}
        <span
          style="position: relative; font-size: 1.2rem; left:-0.4rem"
        >
          {{ productPeriod }}
        </span>
      </h1>
      <h1 v-else>
        <span
          style="position: relative; font-size: 1.4rem; left:-0.2rem; top: -0.6rem;"
        >
          &euro;
        </span>
        0
        <span
          style="position: relative; font-size: 1.2rem; left:-0.4rem"
        >
          {{ productPeriod }}
        </span>
      </h1>
    </v-card-title>
    <v-card-subtitle
      class="d-flex justify-center"
    >
      <v-chip
        v-if="product.price === 0"
        class="mt-2"
        label
        outlined
        color="success"
      >
        Piano gratuito
      </v-chip>
    </v-card-subtitle>
    <v-divider></v-divider>
    <v-card-text>
      <div
        class="d-flex"
      >
        <v-list
          disabled
        >
          <v-list-item-group>
            <v-list-item
              v-for="(item, index) in longDescription"
              :key="index"
            >
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.title }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </div>
    </v-card-text>
    <v-spacer></v-spacer>
  </v-card>
</template>

<script>
export default {
  name: 'LicenseCardSingle',
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: {
        chooseSubscription: false,
      },
      priceTypologyToShow: 'monthly',
    }
  },
  computed: {
    productTypology() {
      return this.product.typology === 'annual' ? 'Annuale' : 'Mensile'
    },
    productPeriod() {
      return this.product.typology === 'annual' ? '/ Anno' : '/ Mese'
    },
    longDescription() {
      return JSON.parse(this.product.long_description)
    },
  },
  methods: {
    handleChooseSubscription() {
      this.$emit('choose-subscription', this.product.prices[this.priceTypologyToShow].product_id, this.product.prices[this.priceTypologyToShow].stripe_price)
    },
    setTypologyPrice(typology) {
      this.priceTypologyToShow = typology
    },
  },
}
</script>
