import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VDialog,{attrs:{"persistent":"","max-width":"800px"},model:{value:(_vm.isShow),callback:function ($$v) {_vm.isShow=$$v},expression:"isShow"}},[_c(VCard,[_c(VCardTitle,[_c('span',{staticClass:"text-h5"},[_vm._v("Seleziona un nuovo abbonamento")])]),_c(VCardText,[_c(VRow,{staticClass:"ma-0"},[_c(VCol,{attrs:{"cols":"12"}},[_c(VSelect,{attrs:{"items":_vm.productsListFiltered,"item-text":"description","item-value":"id","label":"Prodotto","outlined":"","dense":""},model:{value:(_vm.selectedProduct),callback:function ($$v) {_vm.selectedProduct=$$v},expression:"selectedProduct"}})],1),_c(VCol,{attrs:{"cols":"12"}},[(_vm.selectedProduct)?_c('license-card-single',{attrs:{"product":_vm.selectedProductObj}}):_vm._e()],1)],1),_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VAlert,{attrs:{"type":"info","outlined":"","dense":""}},[_c('span',{staticClass:"text-h6"},[_vm._v("Attenzione")]),_c('br'),_c('span',{staticClass:"text-body-1"},[_vm._v("La tua prossima fattura verrà emessa con il nuovo importo, precisamente in data "+_vm._s(_vm._f("timestampToDate")(_vm.currentSubscription.stripe.current_period_end)))])])],1)],1)],1),_c(VCardActions,[_c(VBtn,{staticClass:"ma-2",attrs:{"disabled":_vm.loading},on:{"click":_vm.closeModal}},[_vm._v(" Annulla ")]),_c(VSpacer),_c(VBtn,{staticClass:"ma-2",attrs:{"color":"primary","loading":_vm.loading},on:{"click":_vm.changeSubscription}},[_vm._v(" Conferma ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }