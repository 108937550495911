<template>
  <div>
    <v-container>
      <v-row
        dense
      >
        <v-col
          cols="12"
        >
          <v-card
            :loading="loadingData"
          >
            <v-card-title>
              Licenza di {{ loggedUserCompany.name }}
              <v-spacer />
              <v-btn
                color="primary"
                rounded
                class="my-3 mx-2 hidden-xs-only"
                @click="reloadLicenseData"
              >
                <v-icon>mdi-reload</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <v-container
                v-if="displayContainers1"
              >
                <v-row>
                  <v-col
                    cols="12"
                  >
                    <v-alert
                      border="left"
                      color="red"
                      dark
                    >
                      Non ci sono licenze attive, scegli la tua licenza per accedere a tutti i servizi.
                    </v-alert>
                  </v-col>
                </v-row>
                <template>
                  <v-stepper
                    v-model="step"
                    vertical
                  >
                    <v-stepper-step
                      :complete="step > 1"
                      step="1"
                    >
                      Scegli una licenza
                      <small
                        v-if="selected_product > 0"
                        class="mt-1"
                      >{{ showSelectedProduct.name }}</small>
                      <small
                        v-else
                        class="mt-1"
                      >
                        Tutte le licenze sono periodiche e vengono rinnovate automaticamente lo stesso giorno del mese/anno successivo.
                      </small>
                    </v-stepper-step>
                    <v-stepper-content step="1">
                      <v-row
                        class="pa-3"
                      >
                        <v-col
                          v-for="(product, idx) in groupProducts"
                          :key="'PAY_'+idx"
                          cols="4"
                        >
                          <license-card
                            :product="product"
                            @choose-subscription="handleChooseSubscription"
                          />
                        </v-col>
                      </v-row>
                      <v-row
                        class="pa-3"
                      >
                        <v-col
                          v-for="(product, idx) in freeProducts"
                          :key="'FREE_'+idx"
                          cols="4"
                        >
                          <license-card
                            :product="product"
                            @choose-subscription="handleChooseSubscription"
                          />
                        </v-col>
                      </v-row>
                    </v-stepper-content>

                    <v-stepper-step
                      :complete="step > 2"
                      step="2"
                    >
                      Dati di fatturazione
                    </v-stepper-step>

                    <v-stepper-content step="2">
                      <v-form
                        ref="formPayData"
                        @submit.prevent
                      >
                        <v-card
                          class="pa-3"
                        >
                          <v-card-text>
                            <v-row
                              class="mt-3"
                            >
                              <v-col
                                cols="12"
                                md="4"
                              >
                                <v-text-field
                                  v-model="localCompanyData.name"
                                  label="Nominativo"
                                  type="text"
                                  outlined
                                  dense
                                  :rules="[formRules.required]"
                                ></v-text-field>
                              </v-col>
                              <v-col
                                cols="12"
                                md="4"
                              >
                                <v-text-field
                                  v-model="localCompanyData.email"
                                  label="Email aziendale"
                                  type="email"
                                  outlined
                                  dense
                                  :rules="[formRules.required,formRules.emailRules]"
                                ></v-text-field>
                              </v-col>
                              <v-col
                                cols="12"
                                md="4"
                              >
                                <v-text-field
                                  v-model="localCompanyData.piva"
                                  label="Partita Iva"
                                  type="text"
                                  outlined
                                  dense
                                  :rules="[formRules.required]"
                                ></v-text-field>
                              </v-col>
                            </v-row>
                            <v-row
                              class="mt-3"
                            >
                              <v-col
                                cols="12"
                                md="4"
                              >
                                <v-text-field
                                  v-model="localCompanyData.address"
                                  label="Indirizzo"
                                  type="text"
                                  outlined
                                  :rules="[formRules.required]"
                                  dense
                                ></v-text-field>
                              </v-col>
                              <v-col
                                cols="12"
                                md="4"
                              >
                                <v-text-field
                                  v-model="localCompanyData.city"
                                  label="Città"
                                  type="text"
                                  outlined
                                  :rules="[formRules.required]"
                                  dense
                                ></v-text-field>
                              </v-col>
                              <v-col
                                cols="12"
                                md="2"
                              >
                                <v-select
                                  v-model="localCompanyData.country"
                                  label="Nazione"
                                  type="text"
                                  outlined
                                  :rules="[formRules.required]"
                                  dense
                                  :items="countries"
                                ></v-select>
                              </v-col>
                              <v-col
                                cols="12"
                                md="2"
                              >
                                <v-text-field
                                  v-model="localCompanyData.zip_code"
                                  label="CAP"
                                  type="text"
                                  outlined
                                  :rules="[formRules.required]"
                                  dense
                                ></v-text-field>
                              </v-col>
                            </v-row>
                          </v-card-text>
                          <v-card-actions>
                            <v-btn
                              @click="step = 1"
                            >
                              Indietro
                            </v-btn>
                            <v-spacer></v-spacer>
                            <v-btn
                              v-if="selectedProduct.price > 0"
                              color="primary"
                              @click="checkStep3"
                            >
                              Vai al pagamento
                            </v-btn>
                            <v-btn
                              v-else
                              color="primary"
                              :loading="paymentProcessing"
                              @click="processPayment"
                            >
                              CONFERMA LICENZA
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-form>
                    </v-stepper-content>

                    <v-stepper-step
                      v-if="selected_product === 0 || selectedProduct.price > 0"
                      :complete="step > 3"
                      step="3"
                    >
                      Pagamento
                    </v-stepper-step>

                    <v-stepper-content step="3">
                      <v-form
                        ref="formPay"
                        @submit.prevent
                      >
                        <v-card>
                          <v-card-text>
                            <div class="flex flex-wrap -mx-2 mt-4">
                              <div class="p-2 w-full">
                                <label
                                  for="card-element"
                                  class="leading-7 text-sm text-gray-600"
                                >Credit Card Information</label>
                                <div id="card-element">
                                </div>
                              </div>
                            </div>
                          </v-card-text>
                          <v-card-actions>
                            <v-btn
                              @click="step = 2"
                            >
                              Indietro
                            </v-btn>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="primary"
                              :loading="paymentProcessing"
                              @click="processPayment"
                            >
                              Paga ora
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-form>
                    </v-stepper-content>
                  </v-stepper>
                </template>
              </v-container>
              <v-container
                v-if="Object.keys(currentSubscription).length"
              >
                <v-row
                  class="pa-3"
                >
                  <v-col
                    class="ma-auto"
                    cols="12"
                    lg="8"
                    xl="6"
                  >
                    <v-card
                      elevation="4"
                    >
                      <v-card-title>
                        {{ currentSubscription.product.name }}
                      </v-card-title>
                      <v-card-text>
                        <v-simple-table>
                          <tbody>
                            <tr>
                              <td>
                                Stato:
                              </td>
                              <td>
                                {{ convertStatus(currentSubscription.stripe.status,currentSubscription.stripe.cancel_at_period_end) }}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                Periodo:
                              </td>
                              <td>
                                {{ convertPeriod(currentSubscription.stripe.plan.interval) }}
                              </td>
                            </tr>
                            <tr v-if="!currentSubscription.stripe.canceled_at">
                              <td>
                                Prossimo addebito
                              </td>
                              <td>
                                {{ currentSubscription.stripe.current_period_end | timestampToDate }}
                              </td>
                            </tr>
                            <tr v-else>
                              <td>
                                Valido fino al
                              </td>
                              <td>
                                {{ currentSubscription.stripe.current_period_end | timestampToDate }}
                              </td>
                            </tr>
                          </tbody>
                        </v-simple-table>
                        <v-alert
                          v-if="currentSubscription.stripe.cancel_at_period_end"
                          type="info"
                        >
                          L'abbonamento resterà attivo fino al {{ currentSubscription.stripe.current_period_end | timestampToDate }},
                          è possibile riattivarlo in qualsiasi momento cliccando sul pulsante RIATTIVA
                        </v-alert>
                        <v-alert
                          v-if="currentSubscription.stripe.canceled_at"
                          type="info"
                        >
                          L'abbonamento è stato cancellato e resterà attivo fino al {{ currentSubscription.stripe.current_period_end | timestampToDate }},
                          è possibile scegliere un nuovo abbonamento cliccando sul pulsante CAMBIA ABBONAMENTO
                        </v-alert>
                      </v-card-text>
                      <v-card-actions>
                        <v-btn
                          v-if="!currentSubscription.stripe.canceled_at"
                          :loading="loading.manageSubscription"
                          outlined
                          color="error"
                          @click="suspendSubscription(currentSubscription.stripe_id)"
                        >
                          SOSPENDI
                        </v-btn>
                        <v-btn
                          outlined
                          :loading="loading.manageSubscription"
                          color="error"
                          @click="cancelSubscription(currentSubscription.stripe_id)"
                        >
                          CANCELLA (T)
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="info"
                          @click="changePlanBtn = true"
                        >
                          CAMBIA ABBONAMENTO
                        </v-btn>
                        <v-btn
                          v-if="currentSubscription.stripe.canceled_at"
                          color="success"
                          :loading="loading.reactivateSubscription"
                          @click="reactivateSubscription(currentSubscription.stripe_id)"
                        >
                          RIATTIVA
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <change-plan
        :is-show="changePlanBtn"
        @close="changePlanBtn = false"
      ></change-plan>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { loadStripe } from '@stripe/stripe-js'
import { stripeSecret } from '@/configs/api'
import licenseCard from '@/components/cards/license-card.vue'
import ChangePlan from '@/components/modals/change-plan.vue'

export default {
  name: 'Subscriptions',
  components: {
    licenseCard,
    ChangePlan,
  },
  data() {
    return {
      loadingData: true,
      stripe: {},
      cardElement: {},
      loading: {
        chooseSubscription: false,
        manageSubscription: false,
        reactivateSubscription: false,
      },
      step: 1,
      breadCrumb: [
        {
          text: 'Dashboard',
          to: '/dashboard',
        },
        {
          text: 'Licenze',
          to: '/subscription',
        },
      ],
      countries: [
        'IT',
      ],
      selected_product: 0,
      selected_product_price: '',
      localCompanyData: {},
      paymentProcessing: false,
      paymentData: {},
      changePlanBtn: false,
    }
  },
  computed: {
    ...mapGetters(['formRules', 'loggedUser', 'orderList', 'loggedUserCompany', 'subscriptionsList', 'productsList', 'currentSubscription']),
    showSelectedProduct() {
      if (this.selected_product > 0) {
        return this.productsList.find(item => item.id === this.selected_product)
      }

      return false
    },
    displayContainers1() {
      if (Object.keys(this.currentSubscription).length) {
        return false
      }

      return true
    },
    freeProducts() {
      const prod = this.productsList.filter(item => item.price === 0)
      const prods = {}
      prod.forEach(item => {
        if (!prods[item.stripe_product_id]) {
          prods[item.stripe_product_id] = item
          prods[item.stripe_product_id].prices = []
        }

        const typo = item.typology
        prods[item.stripe_product_id].prices[typo] = {
          price: item.price,
          stripe_price: item.stripe_price,
          product_id: item.id,
        }
      })

      // sort prods object by price
      const sorted = {}
      Object.keys(prods).sort((a, b) => prods[a].price - prods[b].price).forEach(key => {
        sorted[key] = prods[key]
      })

      return sorted
    },
    payingProducts() {
      return this.productsList.filter(item => item.price > 0)
    },
    groupProducts() {
      const prods = {}
      this.payingProducts.forEach(item => {
        if (!prods[item.stripe_product_id]) {
          prods[item.stripe_product_id] = item
          prods[item.stripe_product_id].prices = []
        }

        const typo = item.typology
        prods[item.stripe_product_id].prices[typo] = {
          price: item.price,
          stripe_price: item.stripe_price,
          product_id: item.id,
        }
      })

      // sort prods object by price
      const sorted = {}
      Object.keys(prods).sort((a, b) => prods[a].price - prods[b].price).forEach(key => {
        sorted[key] = prods[key]
      })

      return sorted
    },
    selectedProduct() {
      if (this.selected_product > 0) {
        return this.productsList.find(item => item.id === this.selected_product)
      }

      return false
    },
  },
  async mounted() {
    await this.$store.dispatch('setBreadCrumbLevels', this.breadCrumb)
    if (this.subscriptionsList.length === 0) {
      await this.$store.dispatch('getSubscriptions')
    }
    this.loadingData = false

    this.stripe = await loadStripe(stripeSecret)

    if (!Object.keys(this.currentSubscription).length) {
      const elements = this.stripe.elements()
      this.cardElement = elements.create('card', {
        classes: {
          base: 'bg-gray-100 rounded border border-gray-300 focus:border-indigo-500 text-base outline-none text-gray-700 p-3 leading-8 transition-colors duration-200 ease-in-out',
        },
      })
      this.cardElement.mount('#card-element')
    }
  },
  methods: {
    reloadLicenseData() {
      this.loadingData = true
      this.$store.dispatch('getSubscriptions').then(() => {
        this.loadingData = false
      })
    },
    setSelectedProduct(productId, stripePrice) {
      this.selected_product = productId
      this.selected_product_price = stripePrice
    },
    copyLocalCompanyData() {
      this.localCompanyData = JSON.parse(JSON.stringify(this.loggedUserCompany))
    },
    convertPeriod(period) {
      switch (period) {
        case 'month':
          return 'Mensile'
        case 'year':
          return 'Annuale'
        default:
          return ''
      }
    },
    convertStatus(status, cancelAtPeriodEnd) {
      switch (status) {
        case 'incomplete':
          return 'Tentativo di pagamento fallito'
        case 'active':
          if (cancelAtPeriodEnd) {
            return 'Rinnovo automatico disabilitato'
          }

          return 'Attivo'
        case 'incomplete_expired':
          return 'Chiuso per mancato pagamento'
        case 'trialing':
          return 'In periodo di prova'
        case 'past_due':
          return 'Scaduto'
        case 'canceled':
          return 'Cancellato'
        case 'unpaid':
          return 'Non pagato'
        default:
          return ''
      }
    },
    checkStep3() {
      if (this.$refs.formPayData.validate()) {
        this.step = 3
      } else {
        this.error = 'Alcuni campi sono mancanti.'
      }
    },
    async suspendSubscription(subscriptionId) {
      this.loading.manageSubscription = true

      try {
        await this.$api.suspendSubscription(subscriptionId).then(async response => {
          if (response.data.status === 'success') {
            await this.$store.dispatch('reload_subscription_data')
            this.loading.reactivateSubscription = false
            this.loading.manageSubscription = false
            await this.$store.dispatch('set_alert', { msg: response.data.message, type: 'success' })
          } else {
            await this.$store.dispatch('set_alert', { msg: response.data.message, type: 'error' })
          }
        })
      } catch (e) {
        alert(e)
        this.loading.manageSubscription = false
      }
    },
    async reactivateSubscription(subscriptionId) {
      this.loading.reactivateSubscription = true

      try {
        await this.$api.reactivateSubscription(subscriptionId).then(async response => {
          if (response.data.status === 'success') {
            await this.$store.dispatch('reload_subscription_data')
            this.loading.reactivateSubscription = false
            this.loading.manageSubscription = false
            await this.$store.dispatch('set_alert', { msg: response.data.message, type: 'success' })
          } else {
            await this.$store.dispatch('set_alert', { msg: response.data.message, type: 'error' })
          }
        })
      } catch (e) {
        alert(e)
        this.loading.manageSubscription = false
      }
    },

    // TEST METHOD
    async cancelSubscription(subscriptionId) {
      this.loading.manageSubscription = true

      try {
        await this.$api.cancelSubscription(subscriptionId).then(async response => {
          if (response.data.status === 'success') {
            await this.$store.dispatch('reload_subscription_data')
            this.loading.manageSubscription = false
          } else {
            await this.$store.dispatch('set_alert', { msg: response.data.message, type: 'error' })
          }
        })
      } catch (e) {
        alert(e)
        this.loading.manageSubscription = false
      }
    },
    async processPayment() {
      // send informations to Stripe
      this.paymentProcessing = true

      const freeProduct = !this.selectedProduct.price > 0
      let errorProcessing = false
      if (!freeProduct) {
        const { paymentMethod, error } = await this.stripe.createPaymentMethod(
          'card', this.cardElement, {
            billing_details: {
              name: this.localCompanyData.name,
              email: this.localCompanyData.email,
              address: {
                line1: this.localCompanyData.address,
                city: this.localCompanyData.city,
                country: this.localCompanyData.country,
                postal_code: this.localCompanyData.zip_code,
              },
            },
          },
        )

        this.localCompanyData.payment_method_id = paymentMethod.id
        this.paymentProcessing = false
        errorProcessing = error
      }

      if (!errorProcessing) {
        this.localCompanyData.price_id = this.selected_product_price
        this.localCompanyData.amount = this.productsList.find(item => item.id === this.selected_product).price
        this.localCompanyData.product_id = this.productsList.find(item => item.id === this.selected_product).id
        this.localCompanyData.stripe_product_id = this.productsList.find(item => item.id === this.selected_product).stripe_product_id

        await this.$api.renewProduct(this.localCompanyData, this.localCompanyData.id).then(async () => {
          await this.$store.dispatch('reload_all_data')
          await this.$store.dispatch('set_load_data', true).then(() => { this.paymentProcessing = false })
        })
          .catch(() => {
            this.paymentProcessing = false
            this.$store.dispatch('set_alert', { msg: 'Errore nel pagamento, si prega di riprovare più tardi', type: 'error' })
          })
      }
    },
    handleChooseSubscription(productId, stripePrice) {
      this.step = 2
      this.setSelectedProduct(productId, stripePrice)
      this.copyLocalCompanyData()
    },
  },
}
</script>
