<template>
  <div>
    <v-dialog
      v-model="isShow"
      persistent
      max-width="800px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Seleziona un nuovo abbonamento</span>
        </v-card-title>
        <v-card-text>
          <v-row class="ma-0">
            <v-col
              cols="12"
            >
              <v-select
                v-model="selectedProduct"
                :items="productsListFiltered"
                item-text="description"
                item-value="id"
                label="Prodotto"
                outlined
                dense
              />
            </v-col>
            <v-col
              cols="12"
            >
              <license-card-single
                v-if="selectedProduct"
                :product="selectedProductObj"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
            >
              <v-alert
                type="info"
                outlined
                dense
              >
                <span class="text-h6">Attenzione</span>
                <br>
                <span class="text-body-1">La tua prossima fattura verrà emessa con il nuovo importo, precisamente in data {{ currentSubscription.stripe.current_period_end | timestampToDate }}</span>
              </v-alert>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            class="ma-2"
            :disabled="loading"
            @click="closeModal"
          >
            Annulla
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            class="ma-2"
            :loading="loading"
            @click="changeSubscription"
          >
            Conferma
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import { mapGetters } from 'vuex'
import licenseCardSingle from '@/components/cards/license-card-single.vue'

export default {
  name: 'ChangePlanModal',
  components: { licenseCardSingle },
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      selectedProduct: 0,
      selectedProductObj: {},
    }
  },
  computed: {
    ...mapGetters(['loggedUser', 'loggedUserCompany', 'productsList', 'currentSubscription']),
    productsListFiltered() {
      return this.productsList.filter(item => item.stripe_product_id !== this.currentSubscription.product.id)
    },
  },
  watch: {
    selectedProduct(val) {
      if (val) {
        this.selectedProductObj = this.productsListFiltered.find(item => item.id === val)
      }
    },
  },
  mounted() {

  },
  methods: {
    closeModal() {
      this.$emit('close')
      this.loading = false
    },
    async changeSubscription() {
      try {
        this.loading = true
        const data = {
          product_id: this.selectedProductObj.id,
          price_id: this.selectedProductObj.stripe_price,
          stripe_product_id: this.selectedProductObj.stripe_product_id,
        }
        await this.$api.changeSubscription(this.currentSubscription.id, data).then(async response => {
          if (response.data.status === 'success') {
            await this.$store.dispatch('getCurrentSubscription')
            await this.$store.dispatch('getLicense')
            await this.$store.dispatch('getOrders')
            await this.$store.dispatch('set_alert', { msg: response.data.message, type: 'success' })
            this.closeModal()
          } else {
            await this.$store.dispatch('set_alert', { msg: response.data.message, type: 'error' })
            this.loading = false
          }
        })
      } catch (e) {
        alert(e)
        this.loading = false
      }
    },
  },
}
</script>

<style scoped>

</style>
